import React from "react";
import { Seo, NavCustom, Footer } from "../components";

const about = () => {
	return (
		<div className='w-screen' id="root">
			<Seo
				title='About TNT Auto Window Tinting'
				description='Here is our story, a car window tinting that proudly serves Ottawa'
			/>
			<NavCustom />
			<div className='flex flex-col items-center w-screen'>
				<h1 className='md:lineheigh65 leading-normal font-medium text-3xl text-center mt-8 text-text-second'>
					About Us
				</h1>
				<p className=' text-text-second mt-4 font-semibold text-xl text-center px-8 w-screen'>
					We have served more than 20 years in the tinting field and
					we're proudly gather a lot of trusted and satsified
					customers. Our first team priority is your satisfaction.
					Thanks for choosing us.
				</p>
			</div>
			<Footer />
		</div>
	);
};

export default about;
